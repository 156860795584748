<template>
  <div class="myInvitation">
    <img
      src="https://cdn.bubbleplan.cn/static/home/backPage.png"
      alt=""
      class="myInvitation-back"
      @click="back()"
    />
    <div class="myInvitation-bagw">
      <div class="myInvitation-invite">
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group102462x.png"
          alt=""
          class="myInvitation-invite-img"
        />
        <div class="myInvitation-invite-title">您的邀请码</div>
        <el-input v-model="userInfo.userId" disabled>
          <img
            src="https://cdn.bubbleplan.cn/static/home/Maskgroup2x.png"
            alt=""
            slot="suffix"
            class="myInvitation-invite-maskgroup"
          />
        </el-input>
        <div class="myInvitation-invite-title">
          {{ isDisabled ? "邀请用户uid" : "绑定邀请码" }}
        </div>
        <el-input
          v-model="superiorUserId"
          placeholder="请输入用户uid"
          :disabled="isDisabled"
        >
        </el-input>
        <div
          class="myInvitation-button"
          v-if="!isDisabled"
          @click="confirmAddition()"
        >
          确定
        </div>
      </div>
      <div class="myInvitation-list">
        <div class="myInvitation-invite-title">邀请列表</div>
        <div class="myInvitation-list-ul flex" @scroll="handleScroll">
          <div
            v-for="(item, index) in assignmentList"
            :key="index"
            class="myInvitation-list-ul-li"
          >
            <div>{{ item.invitationUserId }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      superiorUserId: "",
      isDisabled: false,
      hasNextPage: false,
      page: {
        pageNum: 1,
        pageSize: 30,
      },
      assignmentList: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("user_info"));
    this.mySuperior();
    this.mySubordinates();
  },
  methods: {
    //查看下级
    async mySubordinates(type) {
      const params = {
        ...this.page,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      this.hasNextPage = data.hasNextPage;
      if (type == "bottom") {
        this.assignmentList.push(...data.list);
      } else {
        this.assignmentList = data.list;
      }
    },
    //绑定邀请人
    async confirmAddition() {
      if (!this.superiorUserId) return;
      const { data, code } = await this.$ajax.bindingSuperior({
        superiorUserId: this.superiorUserId,
      });
      if (code == 200) {
        this.isDisabled = true;
      }
    },
    //查看上级
    async mySuperior() {
      const { data, code } = await this.$ajax.mySuperior();
      this.superiorUserId = data;
      if (this.superiorUserId) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.mySubordinates("bottom");
        }
      }
    },
    back() {
      history.back();
    },
  },
};
</script>


<style lang="scss" scoped>
.myInvitation::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.myInvitation {
  width: 100%;
  height: calc(100vh - 94px);
  overflow: auto;
  background: url("https://cdn.bubbleplan.cn/static/home/activityPage.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-back:hover {
    cursor: pointer;
  }
  &-bagw::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-back {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-invite {
    width: 909px;
    height: 516px;
    background: url("https://cdn.bubbleplan.cn/static/home/Group104402x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 32px 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    &-img {
      width: 184px;
      height: 49px;
    }
    &-title {
      color: #fef5de;
      text-align: center;
      text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
        2px 2px 0 #341030;
      /* 模拟描边效果 */
      font-family: "TsangerYuMo";
      font-size: 26px;
      font-weight: bold;
      margin-top: 44px;
      margin-bottom: 13px;
    }
    &-maskgroup:hover {
      cursor: pointer;
    }
    &-maskgroup {
      width: 35px;
      height: 43px;
      margin-top: 12px;
      margin-right: 69px;
    }
    :deep(.el-input.is-disabled .el-input__inner) {
      height: 67px;
      background: url("https://cdn.bubbleplan.cn/static/home/Vector7552x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #eeebe3;
      font-size: 26px;
      font-weight: Bold;
      text-align: center;
    }
    :deep(.el-input__inner) {
      height: 67px;
      background: url("https://cdn.bubbleplan.cn/static/home/Vector7552x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #eeebe3;
      font-size: 26px;
      font-weight: Bold;
      text-align: center;
    }
    :deep(.el-input__inner)::placeholder {
      color: #eeebe3;
    }
  }
  &-button:hover {
    cursor: pointer;
  }
  &-button {
    width: 181px;
    height: 77px;
    background: url("https://cdn.bubbleplan.cn/static/home/Frame96182x.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: 40px;
    text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690, -3px 3px 0 #533690,
      3px 3px 0 #533690;
    /* 模拟描边效果 */
    color: #f1eae2;
    text-align: center;
    line-height: 77px;
    font-size: 30px;
    font-weight: bold;
  }
  &-list {
    margin-top: 45px;
    width: 974px;
    height: 500px;
    background: url("https://cdn.bubbleplan.cn/static/home/Group104392x.png")
      no-repeat;
    background-size: 100% 100%;
    margin-left: -35px;
    padding: 30px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    &-ul::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-ul {
      flex-wrap: wrap;
      flex: 1;
      overflow: auto;
      margin-top: 20px;
      &-li {
        width: 33.33%;
        padding: 15px 0;
        text-align: center;
        color: #574352;
        font-size: 26px;
        font-weight: Bold;
        border-bottom: 1px solid rgba(77, 79, 97, 0.35);
      }
    }
  }
}
</style>